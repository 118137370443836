/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $grid = $('.grid').masonry({
          itemSelector: '.grid-item'

        });
        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });

        $(document).ready(function() {

          // Gets the video src from the data-src on each button

          var $videoSrc;  
          $('.video-btn').click(function() {
              $videoSrc = $(this).data( "src" );
          });
          console.log($videoSrc);

            
            
          // when the modal is opened autoplay it  
          $('#myModal').on('shown.bs.modal', function (e) {
              
          // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
          $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
          });
            


          // stop playing the youtube video when I close the modal
          $('#myModal').on('hide.bs.modal', function (e) {
              // a poor man's stop video
              $("#video").attr('src',$videoSrc); 
          }); 
          // document ready  
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //-----------------------------------------

        // ANIMATION  FOR FULL HERO HOME PAGE
        var width = $(window).width();
        if (width>920){
          TweenMax.to($('.fullhero'),2.5,{xPercent:-5}).delay(1);
          TweenMax.to($('.heroTextContent'),4.5,{xPercent:60, alpha:1}).delay(1);
        }
        else{
          TweenMax.to($('.fullhero'),2.5,{'-webkit-filter':'brightness(65%)'}).delay(0);
          TweenMax.to($('.heroTextContent'),4.5,{xPercent:0, alpha:1}).delay(1);
        }
        
        // MENU ANIMATION

        var t1 = TweenMax.fromTo('.banner.bannerHome', 0.5, {autoAlpha:0}, {autoAlpha:1});
        var controller = new ScrollMagic.Controller(); 
        var scene = new ScrollMagic.Scene({
            triggerElement: ".homeContent",
            triggerHook: 0
          })
          //.addIndicators({
          //  name: "Menu1",
          //  colorTrigger: "white",
          //  colorStart: "white",
          //  colorEnd: "white"
          //})
          .setTween(t1)
          .setPin(".banner.bannerHome")
          .addTo(controller);
        

        // PARALLAX ON CONTENT HEADER

        var slideParallaxScene = new ScrollMagic.Scene({
          triggerElement: '.homeContent',
          triggerHook: 1,
          duration: "100%"
        })
        .setTween(TweenMax.to('.heroTextContent', 1, {y: '+70%', ease:Power0.easeNone}))
        .addTo(controller);
      },
    },


    // PAGE ARCHIVE EVENTS
    'post_type_archive_tribe_events': {
      init: function() {
                
        // ANIMATION  FOR FULL HERO HOME PAGE
        TweenMax.to($('.fullhero'),6.5,{scaleX:1.2, scaleY:1.2}).delay(2);
        TweenMax.to($('.heroTextContent'),4.5,{ alpha:1}).delay(1);
        
        // MENU ANIMATION

        var t1 = TweenMax.fromTo('.banner.bannerHome', 0.5, {autoAlpha:0}, {autoAlpha:1});
        var controller = new ScrollMagic.Controller(); 
        var scene = new ScrollMagic.Scene({
            triggerElement: ".wrap.container",
            offset : -134,
            triggerHook: 0
          })
          //.addIndicators({
          //  name: "Menu1",
          //  colorTrigger: "white",
          //  colorStart: "white",
          //  colorEnd: "white"
          //})
          .setTween(t1)
          .setPin(".banner.bannerHome")
          .addTo(controller);
        

        // PARALLAX ON CEONTENT HEADER

        var slideParallaxScene = new ScrollMagic.Scene({
          triggerElement: '.homeContent',
          triggerHook: 1,
          duration: "100%"
        })
        .setTween(TweenMax.to('.heroTextContent', 1, {y: '+70%', ease:Power0.easeNone}))
        .addTo(controller);
      }
    },

    
    'single_tribe_events': {
      init: function() {
        // JavaScript to be fired on the about us page
        

        
            
            


          
          
        
      }
    },


    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
